/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unknown-property */
import tw, { css } from "twin.macro";
import { graphql } from "gatsby";
import Select from "react-select";
import { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";

import FloorPlanCard, { Floorplan } from "../../components/FloorPlanCard";
import {
	AppStyles,
	Body,
	H2,
	Subtitle,
	TextLink,
} from "../../styles/AppStyles.styles.tw";

const bedroomOptions = [
	{ value: "anyBedrooms", label: "Any Bedrooms" },
	{ value: "1", label: "1 Bedroom" },
	{ value: "2", label: "2 Bedrooms" },
];

const dropdownStyles = {
	option: (provided: any, state: any) => ({
		...provided,
		"border": "1px gainsboro solid",
		"backgroundColor": state.isSelected
			? "#183D36"
			: "#FFFFFF",
		"outline": "none",
		"fontFamily": "brother-1816",
		"textAlign": "center",
		"color": state.isSelected
			? "#F0ECE6"
			: "rgb(0, 0, 0, .8)",
		":hover": {
			backgroundColor: state.isSelected
				? "#183D36"
				: "#F0ECE6",
			fontWeight: state.isSelected ? "normal" : "bold",
		},
	}),
	menu: (provided: any) => ({
		...provided,
		borderRadius: 0,
		padding: 0,
		backgroundColor: "#F0ECE6",
		fontFamily: "brother-1816",
		border: "1px #183D36 solid",
	}),
	menuList: (provided: any) => ({
		...provided,
		padding: 0,
		fontFamily: "brother-1816",
	}),
	indicatorSeparator: () => ({}),
	control: (provided: any) => ({
		...provided,
		"border": "1px #183D36 solid",
		"borderRadius": 0,
		"backgroundColor": "#F0ECE6",
		"boxShadow": "none",
		"margin": 0,
		"fontFamily": "brother-1816",
		":hover": {
			borderColor: "#183D36",
		},
	}),
	valueContainer: (provided: any) => ({
		...provided,
		textTransform: "uppercase",
		fontWeight: "bold",
		letterSpacing: "0.05rem",
		fontFamily: "brother-1816",
	}),
	singleValue: (provided: any) => ({
		...provided,
		color: "#183D36",
		fontFamily: "brother-1816",
	}),
	dropdownIndicator: (provided: any) => ({
		...provided,
		color: "#183D36",
	}),
};

function FloorPlans({ data }: any): JSX.Element {
	const page = data.sanityFloorplans;

	const floorPlanBed = css`
		background-image: url(${page.heroImage.asset.url});
		@media screen and (min-width: 768px) {
			min-height: 650px;
		}
	`;

	const [floorplans, setFloorplans] = useState<Floorplan[]>(data.floorplans.nodes);

	const [bedroomSelect, setBedroomSelect] = useState({
		value: "anyBedrooms",
		label: "Any Bedrooms",
	});

	const handleBedroomChange = (selectedOption: any) => {
		setBedroomSelect(selectedOption);
	};

	useEffect(() => {
		if (bedroomSelect.value !== "anyBedrooms") {
			const list = floorplans.filter((fp) => fp.beds.toString() === bedroomSelect.value);
			setFloorplans(list);
			return;
		}
		setFloorplans(data.floorplans.nodes);
	}, [bedroomSelect]);

	return (
		<Layout title="floor-plans">
			<SEO
				title={page.pageTitle}
				description={page.pageDescription}
				keywords={page.keywords.join(", ")}
			/>
			<AppStyles>
				<section
					css={[
						tw`flex flex-col items-center justify-center px-4 py-32 text-center text-white bg-center bg-no-repeat bg-cover lg:px-0 2xl:py-48`,
						floorPlanBed,
					]}
				>
					<H2 tw="mb-4">{page.header}</H2>
					<Body tw="max-w-xl">
						{page.body}{" "}
						{page.linkURL && (
							<a href={page.linkURL}>
								<TextLink tw="text-white">
									{page.linkText}
								</TextLink>
							</a>
						)}
					</Body>
				</section>
				<section tw="bg-beige-light py-16 space-y-8 relative">
					<div tw="flex justify-center items-center flex-wrap">
						<Subtitle tw="m-2">FILTER BY:</Subtitle>
						<div tw="grid place-items-center gap-2 grid-cols-1">
							<Select
								styles={dropdownStyles}
								defaultValue={bedroomOptions[0]}
								options={bedroomOptions}
								value={bedroomSelect}
								onChange={handleBedroomChange}
								tw="w-64"
							/>
						</div>
					</div>
					<div tw="flex justify-center items-center">
						<div tw="flex flex-wrap justify-center container px-4">
							{floorplans.map((fp) => (
								<FloorPlanCard key={fp.id} {...fp} />
							))}
						</div>
					</div>
					<div tw="container text-center mx-auto lg:text-left lg:w-10/12">
						<Body tw="text-xs -mb-8 lg:text-sm mx-0">
							Actual layouts, dimensions and finishes will
							vary.
						</Body>
					</div>
				</section>
			</AppStyles>
		</Layout>
	);
}

export default FloorPlans;

export const query = graphql`
	{
		sanityFloorplans {
			header
			heroImage {
				alt
				asset {
					gatsbyImageData
					url
				}
			}
			keywords
			pageDescription
			pageTitle
			linkText
			linkURL
			body
		}
		floorplans: allFloorplan {
			nodes {
				baths
				beds
				id
				image
				name
				price
				sqFt
			}
		}
	}
`;
