import React from "react";
import tw, { css } from "twin.macro";
import ReactDom from "react-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import GreenPattern from "../images/patterns/Green_Pattern.png";

const modalOverlay = css`
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: -10px;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 990;
`;

const modalClass = css`
	position: relative;
	width: 80vw;
	max-height: 80vh;
	// position: fixed;
	// top: 50%;
	// left: 50%;
	// transform: translate(-50%, -50%);
	overflow-y: auto;
	background-image: url(${GreenPattern});
	color: white;
	padding: 2rem;
	z-index: 1000;
	box-sizing: border-box;
	@media screen and (min-width: 1366px) {
		width: auto;
	}
`;

const modalClose = css`
	position: absolute;
	right: 0;
	top: 0;
	width: 1rem;
	height: 1rem;
	margin: 0.5rem;
	&:focus {
		outline: none;
	}
	@media screen and (min-width: 1366px) {
		margin: 0.75rem;
	}
`;

function Modal({
	open,
	children,
	onClose,
}: {
	open: boolean;
	children: JSX.Element;
	onClose: () => void;
}): JSX.Element {
	const portalRoot =
		typeof document !== `undefined`
			? document.querySelector("#portal")
			: undefined;

	const backdrop = {
		hidden: { opacity: 0 },
		show: { opacity: 1 },
	};

	const modal = {
		hidden: {
			y: 100,
			opacity: 0,
		},
		show: {
			y: 0,
			opacity: 1,
		},
	};

	return ReactDom.createPortal(
		<>
			{open && (
				<motion.div
					variants={backdrop}
					initial="hidden"
					animate="show"
					exit="hidden"
					css={[modalOverlay]}
					onClick={onClose}
					onKeyDown={onClose}
					role="button"
					tabIndex={0}
				>
					<motion.div
						variants={modal}
						css={[modalClass]}
						onClick={(e) => {
							e.stopPropagation();
						}}
						onKeyDown={(e) => {
							e.stopPropagation();
						}}
						role="button"
						tabIndex={-1}
					>
						{children}
						<button
							onClick={onClose}
							onKeyDown={onClose}
							css={[modalClose]}
							tabIndex={-1}
							type="button"
						>
							<FontAwesomeIcon icon={faTimes} />
						</button>
					</motion.div>
				</motion.div>
			)}
		</>,
		portalRoot
	);
}

export default Modal;
