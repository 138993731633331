/* eslint-disable react/no-unknown-property */
import { useState } from "react";
import tw from "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence } from "framer-motion";
import { Link } from "gatsby";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
	Body,
	ButtonAvailability,
	ButtonOrange,
	H3,
} from "../styles/AppStyles.styles.tw";
import Modal from "./Modal";

export type Floorplan = {
	name: string;
	price: number;
	image: string;
	beds: number;
	baths: number;
	sqFt: number;
	id: number;
};

function FloorPlanCard({
	name,
	price,
	image,
	beds,
	baths,
	sqFt,
	id,
}: Floorplan): JSX.Element {
	const [modalOpen, setModalOpen] = useState(false);

	return (
		<div
			css={[
				tw`w-80 h-96 p-4 space-y-4 border border-black text-center margin[-.5px]`,
			]}
		>
			<div tw="flex justify-between items-end">
				<H3>{name}</H3>
				{price !== -1 ? (
					<Body tw="font-title mx-0">
						{
							price
								.toLocaleString(`en-US`, {
									style: `currency`,
									currency: `USD`,
								})
								.split(`.`)[0]
						}
					</Body>
				) : (
					<Body tw="font-title mx-0">Call for pricing</Body>
				)}
			</div>
			<img
				tw="w-64 h-52 object-cover object-center mx-auto"
				src={image}
				alt={name}
			/>
			<div tw="flex justify-between items-center border-t border-b border-black text-center">
				<div tw="px-2">
					<button
						type="button"
						onClick={() => setModalOpen(true)}
						tw="focus:outline-none"
					>
						<span tw="sr-only">Search</span>
						<FontAwesomeIcon icon={faSearch as IconProp} />
					</button>

					<AnimatePresence exitBeforeEnter>
						{modalOpen && (
							<Modal
								open={modalOpen}
								onClose={() => setModalOpen(false)}
								key="Sponsorship Form"
							>
								<div tw="flex flex-col justify-center items-center space-y-4">
									<img
										tw="object-cover object-center mx-auto"
										src={image}
										alt={name}
									/>
									<a
										href={`/floor-plans/McCaffery-Encore-${name}.pdf`}
										download
									>
										<ButtonOrange>Download PDF</ButtonOrange>
									</a>
								</div>
							</Modal>
						)}
					</AnimatePresence>
				</div>
				<Body tw="border-l border-black mx-0 pl-4 pr-2">
					{beds > 1 ? `${beds} beds` : `${beds} bed`} -{" "}
					{baths > 1 ? `${baths} baths` : `${baths} bath`}
				</Body>
				<Body tw="border-l border-black mx-0 px-2">
					{sqFt.toLocaleString()} sq. ft.
				</Body>
			</div>
			<Link to={`/floor-plans/${name}`}>
				<ButtonAvailability tw="bg-navy">
					Check Availability
				</ButtonAvailability>
			</Link>
		</div>
	);
}

export default FloorPlanCard;
